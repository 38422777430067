// @file Composable for processing text in post and comment body

import { trackEvent } from '@@/bits/analytics'
import { autoLink } from '@@/bits/autolinker'
import { ALERT_ICON } from '@@/bits/confirmation_dialog'
import device from '@@/bits/device'
import { captureException } from '@@/bits/error_tracker'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { $ } from '@@/bits/jquery'
import { getHostnameFromUrl, navigateTo } from '@@/bits/location'
import {
  getNativeLinkPayloadForInternalLink,
  makeBodyPadletLinksInternalForNativeBridge,
  mathifyPostBody,
  setRedirectUrlsForExternalLinks,
} from '@@/bits/post_processing'
import { isInternalUrl } from '@@/bits/url'
import { OzConfirmationDialogBoxButtonScheme } from '@@/library/v4/components/OzConfirmationDialogBox.vue'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import type { NativeAppOpenLink } from '@@/pinia/native_app'
import { useNativeAppStore } from '@@/pinia/native_app'
import { useSurfaceCurrentUserStore } from '@@/pinia/surface_current_user'
import PadletApi from '@@/surface/padlet_api'
import type { LinkSafetyCheckApiResponse } from '@@/types'
import { storeToRefs } from 'pinia'
import { nextTick } from 'vue'

const fetchedLinks = new Map<string, LinkSafetyCheckApiResponse>()

const navigateToUrl = (link: string): void => {
  const nativeAppStore = useNativeAppStore()
  if (device.app) {
    const linkPayload: Omit<NativeAppOpenLink, 'url'> = isInternalUrl(link)
      ? getNativeLinkPayloadForInternalLink(link)
      : { linkType: 'external' }

    nativeAppStore.openLink({
      url: link,
      ...linkPayload,
    })
  } else {
    navigateTo(link, { target: '_blank' })
  }
}

const navigateToUnsafeUrl = (link: string): void => {
  trackEvent('Link Safety', 'Visited unsafe link', 'Link', link)
  navigateToUrl(link)
}

export const handleLinkClick = async (
  link: string,
  linkAuthorHashid: string | undefined = undefined,
): Promise<void> => {
  try {
    let linkSafetyCheckResponse: LinkSafetyCheckApiResponse
    const linkHost = getHostnameFromUrl(link)
    const { currentUser } = storeToRefs(useSurfaceCurrentUserStore())
    if (currentUser.value?.hashid === linkAuthorHashid) {
      // no need to check the safety when post author clicks on a link added by themselves
      trackEvent('Link Safety', 'Visited self-added link', 'Link', link)
      navigateToUrl(link)
      return
    }
    if (isInternalUrl(linkHost)) {
      // no need to check the safety of padlet links
      navigateToUrl(link)
      return
    }
    if (fetchedLinks.has(link)) {
      linkSafetyCheckResponse = fetchedLinks.get(link) as LinkSafetyCheckApiResponse
    } else {
      linkSafetyCheckResponse = await PadletApi.LinkSafetyCheck.checkLinkSafety({ url: link })
      fetchedLinks.set(link, linkSafetyCheckResponse)
    }
    if (linkSafetyCheckResponse.isSafe === 'false') {
      const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()
      globalConfirmationDialogStore.openConfirmationDialog({
        ...ALERT_ICON,
        title: __('Danger ahead!'),
        body: __(
          '<p class="text-body-small-posts">We do not recommend accessing the following link. Our security scanner says the link contains malware.<br><span class="text-dark-text-200 dark:text-light-text-200 line-clamp-3 break-word-anywhere text-ellipsis">%{link}</span></p>',
          {
            link,
          },
        ),
        confirmButtonText: __('I accept the risk, continue'),
        cancelButtonText: __('Stay on Padlet'),
        afterConfirmActions: [() => navigateToUnsafeUrl(link)],
        afterCancelActions: [() => trackEvent('Link Safety', 'Cancelled unsafe link', 'Link', link)],
        forceFullWidthButtons: true,
        buttonScheme: OzConfirmationDialogBoxButtonScheme.Danger,
      })
    } else {
      if (linkSafetyCheckResponse.isSafe === 'true') {
        trackEvent('Link Safety', 'Visited safe link', 'Link', link)
      } else {
        trackEvent('Link Safety', 'Visited unknown link', 'Link', link)
      }
      navigateToUrl(link)
    }
  } catch (error) {
    navigateToUrl(link)
    captureException(error)
  }
}

const fetchLinkSafetyAndUpdateElementLinkBehaviour = async (el: HTMLElement): Promise<void> => {
  const link = el.getAttribute('href')
  if (link == null || link.trim() === '') return

  el.onclick = (event) => {
    event.preventDefault() // Prevent default action
    void handleLinkClick(link)
  }
}

const addWarningsWhenNavigatingUnsafeLinks = async (element: HTMLElement): Promise<void> => {
  $(element)
    .find('a[href^=h]')
    .attr({ target: '_blank', rel: 'noopener nofollow ugc' })
    .each((_, el) => {
      void fetchLinkSafetyAndUpdateElementLinkBehaviour(el)
    })
}

function processLinksInElement(element: HTMLElement): void {
  if (isAppUsing('linkSafetyModal')) {
    void addWarningsWhenNavigatingUnsafeLinks(element)
  } else {
    makeBodyPadletLinksInternalForNativeBridge(element)
    setRedirectUrlsForExternalLinks(element)
  }
}

async function processBodyElement(postBodyElement: HTMLElement): Promise<void> {
  // we require to wait for 2 ticks for the post body element to be fully loaded
  await nextTick()
  await nextTick()
  processLinksInElement(postBodyElement)
  mathifyPostBody(postBodyElement)
}

export async function processPostBody(originalBody: string, postBodyElement: HTMLElement): Promise<string> {
  const newBody = await autoLink(originalBody)
  // fire and forget the functions that involve the DOM, and return the autolinked string right away
  void processBodyElement(postBodyElement)
  return newBody
}

export async function processCommentBodyElement(commentBodyElement: HTMLElement): Promise<void> {
  await nextTick()
  processLinksInElement(commentBodyElement)
}
